import classNames from "classnames";

import classes from "./Footer.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import type {FooterProps} from "~/@types/components/templates/FooterProps";
import RecaptchaDisclaimer from "~/components/templates/RecaptchaDisclaimer";

export default function Footer({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: FooterProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <footer className={classNames(classPrefix("footer"), propsClassName, styles("footer"))}>
        <div className={"" + styles("content")}>
            <RecaptchaDisclaimer />
        </div>
    </footer>;
}