import Link from "~/components/common/Link";
import Loader from "~/components/common/Loader";
import {useGetAuthSignUpDataQuery} from "~/api/enhancedAuthAPI";
import {classPrefix, createStylesSelector} from "~/lib";
import classes from "./OAuthSignIn.module.pcss";
import {OAuthSignInProps} from "~/@types/components/templates/OAuthSignInProps";
import {IconCustom, IconFilled, IconOutlined} from "~/@types/components/common/IconProps";
import classNames from "classnames";
import {Slug} from "~/config";
import {LinkProps} from "~/@types/components/common/LinkProps";
import {createLoginWithRetURL} from "~/reducers/navigation";

function getProviderIcon(provider: string): IconOutlined | IconFilled | IconCustom {
    let ico: IconOutlined | IconFilled | IconCustom = "Key";

    switch (provider.toLowerCase()) {
        case "amazon":
            ico = "Amazon";
            break;
        case "atlassian":
            ico = "Atlassian";
            break;
        case "facebook":
            ico = "Facebook";
            break;
        case "github":
            ico = "Github";
            break;
        case "google":
            ico = "Google";
            break;
        case "linkedin":
            ico = "Linkedin";
            break;
        case "slack":
            ico = "Slack";
            break;
    }

    return ico;
}

export default function OAuthSignIn({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
    prefixText = "Or continue with",
    Component = "div"
}: OAuthSignInProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    const {data: signupData, isLoading: signupDataLoading} = useGetAuthSignUpDataQuery();

    const withRetURL: LinkProps["withRetURL"] = createLoginWithRetURL();

    const providersOrder = [
        "google",
        "amazon",
        "github",
        "atlassian",
        "slack",
        "facebook",
        "linkedin",
    ];

    const providers = signupData && signupData["oauth-providers"]
        ? [...signupData["oauth-providers"]]
        : [];
    providers.sort((a, b) => {
        const aInd = providersOrder.indexOf(a);
        const bInd = providersOrder.indexOf(b);
        if (aInd >= 0 && bInd >=0) {
            return aInd - bInd;
        } else if (aInd >= 0) {
            return -1;
        } else if (bInd >= 0) {
            return 1;
        } else {
            return (a > b ? 1 : (a === b ? 0 : -1));
        }
    });

    return <Component className={classNames(classPrefix("oauth-sign-in"), propsClassName, styles("oauth-sign-in"))}>
        {!signupDataLoading
            ? (providers.length ? <>
                {prefixText ? <p>{prefixText}</p> : null}
                <ul className={styles("providers").toString()}>
                    {providers.map(provider => <li key={provider} className={classNames(styles("provider", `provider-${provider}`))}>
                        <Link
                            ico={{ico: getProviderIcon(provider), mode: "auto"}}
                            icoPosition="only"
                            slug={Slug.Oauth}
                            slugTplParams={{provider}}
                            to={`/${provider}`}
                            title={provider}
                            withRetURL={withRetURL}
                        />
                    </li>)}
                </ul>
            </> : null)
            : <Loader size={"default"} type={"text"} loadingText={"load providers"}/>}
    </Component>;
}