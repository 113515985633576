import classes from "./DocsGuidesWrapper.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import {useGetDocsGuidesByGuideSlugQuery} from "~/api/docsAPI";
import Loader from "~/components/common/Loader";
import Menu from "~/components/common/Menu";
import Block from "~/components/common/Block";
import Markdown from "~/components/common/Markdown";
import DateTime from "~/components/common/Date";
import classNames from "classnames";
import type {DocsGuidesWrapperProps} from "~/@types/components/templates/DocsGuidesWrapperProps";
import MarkdownUrlTransformContext from "~/context/MarkdownUrlTransformContext";

export default function DocsGuidesWrapper({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    slug,
    rootSlug,
}: DocsGuidesWrapperProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    const guideSlug = slug.replace(new RegExp(`^${rootSlug}-`), "");
    const {
        data: guideData,
        isLoading,
        isFetching,
    } = useGetDocsGuidesByGuideSlugQuery(guideSlug);

    const transform = (url: string): string => {
        if (url.startsWith("guides:")) {
            url = url.replace(
                /guides:([\w-_]+)/,
                (_m, slug) => `slug:${rootSlug}-${slug}`
            );
        }

        return url;
    };

    return <div className={classNames(classPrefix("docs-guides-wrapper"), propsClassName, styles("docs-guides-wrapper", "page-content", "with-sidebar"))}>
        <nav className={"" + styles("sidebar")}>
            <Menu
                styles={styles}
                name="guides"
                mode="inline"
                className={"" + styles("sidebar-menu")}
                subMenuClassName={"" + styles("sidebar-sub-menu")}
                defaultOpenKeys={[slug]}
                openKeys={[slug]}
                defaultSelectedKeys={[slug]}
                inheritKeyPrefix={false}
                slugAsKey={true}
            />
        </nav>
        <div className={"" + styles("guide-data")}>
            {!isLoading && !isFetching
                ? guideData?.guide ? <Block
                    styles={styles}
                    header={guideData.guide.title}
                    meta={[
                        {field: "created", title: "Created", content: <DateTime>{guideData.guide.created}</DateTime>},
                        {field: "updated", title: "Updated", content: <DateTime>{guideData.guide.updated}</DateTime>},
                    ]}
                >
                    <MarkdownUrlTransformContext.Provider value={{urlTransform: transform}}>
                        <Markdown>{guideData.guide.description}</Markdown>
                    </MarkdownUrlTransformContext.Provider>
                </Block> : "no data"
                : <Loader size="large"/>
            }
        </div>
    </div>;
}