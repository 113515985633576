import {RecaptchaDisclaimerProps} from "~/@types/components/templates/RecaptchaDisclaimerProps";
import classNames from "classnames";
import {classPrefix, createStylesSelector} from "~/lib";
import classes from "./RecaptchaDisclaimer.module.pcss";
import Link from "~/components/common/Link";

export default function RecaptchaDisclaimer({
    className: propsClassName,
    classes: propsClasses,
    styles: propsStyles,
}: RecaptchaDisclaimerProps) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <div className={classNames(classPrefix("recaptcha-disclaimer"), propsClassName, styles("recaptcha-disclaimer"))}>
        This site is protected by reCAPTCHA and the Google <Link to="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</Link> and <Link to="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</Link> apply.
    </div>;
}